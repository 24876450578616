<template>
  <div class="row" v-if="loading">
    Loading...
  </div>
  <div class="row m-0" v-else>
    <div class="p-0 clickable text-center"
      :class="[isMobile ? 'col-6 confirmation-mobile' : (isTablet ? 'col-4': 'col-3')]"
      v-for="(attachment, i) of myAttachments" :key="'attachment_'+i" @click="selectItem(attachment)">
      <div class="col-12 p-0">
        <img src="../assets/images/icon_doc.png" class="doc_icn">
      </div>
      <div class="col-12 p-0 mt-2" v-html="title(attachment)" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AdditionalInfo',
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet'
    ])
  },
  async created () {
    const res = await this.$api.guest.additionalInfo()
    this.myAttachments = res.data && res.data.attachments ? res.data.attachments : []
    this.loading = false
  },
  data () {
    return {
      loading: true,
      myAttachments: []
    }
  },
  methods: {
    async selectItem (attachment) {
      const { data } = await this.$api.guest.attachment(attachment.id)
      if (data && data.link) {
        window.open(data.link, '_blank')
      }
    },
    title (a) {
      return a.name.replace('.pdf', '')
    }
  }
}
</script>
<style scoped>
</style>
